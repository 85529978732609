import React, { useEffect, useState } from 'react';
import https from 'https';
import sortBy from 'lodash.sortby';
import { add, format, formatDistance } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import cookie from 'js-cookie';
import {
  capitalize,
  excerpt,
  parseDash,
  parseUnderScore,
  parseValueForUnknown,
} from './text';
import { hasCompletedProfile, userCanEditPost } from './user';
import { isFeaturedPost, isNewApplication, isNewPost } from './jobpost';
import { categories, processCategory } from '../shared/categories';
import { locations, processLocation } from '../shared/locations';
import { contracts } from '../shared/contracts';
import { qualifications } from '../shared/qualifications';
import Button from '../components/common/button';

const useMobileScreenWidth = () => {
  const [isMobileWidth, setIsMobileWidth] = useState(false);

  useEffect(() => {
    handleScreenWidth();
  }, []);

  const handleScreenWidth = () => {
    setIsMobileWidth(typeof window !== 'undefined' && window.outerWidth <= 768);
  };

  return isMobileWidth;
};
const useLgScreenWidth = () => {
  const [isLgWidth, setIsLgWidth] = useState(false);

  useEffect(() => {
    handleScreenWidth();
  }, []);

  const handleScreenWidth = () => {
    setIsLgWidth(typeof window !== 'undefined' && window.outerWidth >= 1024);
  };

  return isLgWidth;
};

const useXLScreenWidth = () => {
  const [isXLWidth, setIsXLWidth] = useState(false);

  useEffect(() => {
    handleScreenWidth();
  }, []);

  const handleScreenWidth = () => {
    setIsXLWidth(typeof window !== 'undefined' && window.outerWidth >= 1280);
  };

  return isXLWidth;
};

const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new window.IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
};

const httpAgent = () =>
  new https.Agent({
    rejectUnauthorized: false,
  });

const getOptions = (name) => {
  const options = [];

  if (name === 'category') {
    sortBy(categories, 'title').forEach((ct) => {
      options.push({
        value: processCategory(ct.title),
        label: ct.title,
      });
    });
  }

  if (name === 'type') {
    contracts.forEach((cont) => {
      options.push({
        value: cont.title.toLowerCase(),
        label: cont.title,
      });
    });
  }

  if (name === 'qualification') {
    qualifications.forEach((q) => {
      options.push({
        value: q.title.toLowerCase(),
        label: q.title,
      });
    });
  }

  if (name === 'location') {
    sortBy(locations, 'name').forEach((l) => {
      options.push({
        value: processLocation(l.name),
        label: l.name,
      });
    });
  }

  return options;
};

const getQueryString = (obj) => new URLSearchParams({ ...obj });

const getYearsInRange = (startYear = 1960, endYear) => {
  const endDate = endYear || new Date().getFullYear();
  const years = [];

  for (let i = startYear; i <= endDate; i++) {
    years.push(startYear);
    startYear++;
  }

  return years.reverse();
};

const trackEvent = ({
  action = 'click',
  category = 'clicks',
  label,
  value,
}) => {
  if (window && window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    });
  }
};

// eslint-disable-next-line no-unused-vars
const isInSplit = (treatments, splitName) =>
  // !isEmpty(treatments) && treatments[splitName] === 'on';
  /**
   * Enable this for production
   */
  true;
const getFromDate = (date, addSuffix = true) => {
  if (!date) return;
  return formatDistance(new Date(date), new Date(), { addSuffix });
};

const formatDate = (date, formatString = 'P') => {
  if (!date) return '';
  return format(new Date(date), formatString);
};

const addToDate = (date, days) => {
  let startDate = date;

  if (!startDate) {
    startDate = new Date();
  }

  return formatDate(add(new Date(startDate), { days }));
};

const getRatingStrokeColor = (rating) => {
  if (rating < 50) {
    return '#F87171';
  }

  if (rating <= 70) {
    return '#034C9D';
  }

  return '#10B981';
};

const getApplicationStatusBg = (application) => {
  let bg = 'bg-blue-50';

  switch (application.status) {
    case 'hired':
      bg = 'bg-blue-tufts';
      break;
    case 'rejected':
      bg = 'bg-red-300';
      break;
    case 'shortlisted':
      bg = 'bg-green-100';
      break;
    case 'new':
      if (isNewApplication(application.created_at)) {
        bg = 'bg-blue-200';
      }
      break;

    default:
      break;
  }

  return bg;
};

const getJobPostStatusBg = (jobpost) => {
  let bg = 'bg-blue-50'; //#f1f6fb

  switch (jobpost.status) {
    case 'rejected':
      bg = 'bg-red-300';
      break;
    case 'expired':
      bg = 'bg-red-300'; //#fca5a5
      break;
    case 'featured':
      bg = 'bg-green-200'; //#a7f3d0
      break;
    case 'new':
      bg = 'bg-blue-200'; //#bfdbfe
      break;

    default:
      break;
  }

  return bg;
};

const getOfferStatusBg = (status) => {
  let bg = 'bg-blue-50';

  switch (status) {
    case 'rejected':
      bg = 'bg-red-300';
      break;
    case 'expired':
      bg = 'bg-red-300';
      break;
    case 'accepted':
    case 'new':
      bg = 'bg-green-200';
      break;

    default:
      break;
  }

  return bg;
};

const getItemStatusBg = (status) => {
  let bg = 'bg-blue-50';

  switch (status.toLowerCase()) {
    case 'rejected':
      bg = 'bg-red-400';
      break;
    case 'pending_review':
    case 'pending_approval':
    case 'pending_user_action':
    case 'pending':
      bg = 'bg-amber-300';
      break;
    case 'approved':
    case 'new':
    case 'shortlisted':
      bg = 'bg-green-400';
      break;
    case 'completed':
      bg = 'bg-blue-100';
      break;

    default:
      break;
  }

  return bg;
};

const getRatingBg = (application) => {
  let bg = 'bg-green-200';

  if (application.rating < 20) {
    bg = 'bg-red-200';
  } else if (application.rating < 50) {
    bg = 'bg-amber-200';
  } else if (application.rating < 60) {
    bg = 'bg-green-100';
  }

  return bg;
};

const getPixeRatio = () => window.devicePixelRatio;

const buildThresholdList = (numSteps = 20) => {
  const thresholds = [];

  for (let i = 1.0; i <= numSteps; i++) {
    const ratio = i / numSteps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
};

const initConfirmAlert = (opts) => {
  const {
    handler,
    title = 'Are you sure?',
    message = 'It may not be possible to undo this action. Are you sure you want to proceed?',
  } = opts;
  confirmAlert({
    customUI: ({ onClose }) => (
      <div className="confirm-dialog flex flex-col w-80 bg-white py-4 px-4 rounded shadow-sm relative">
        <div className="w-full border-gray-200">
          <h5 className="text-xl font-semibold leading-none">{title}</h5>
        </div>
        <hr className="-mx-4 my-3" />
        <div className="w-full py-2">
          <p className="">{message}</p>
        </div>
        <hr className="-mx-4 my-3" />
        <div className="w-full flex justify-between border-gray-200">
          <Button
            design="secondary"
            onClick={() => {
              onClose();
            }}
          >
            No, Let me check.
          </Button>
          <Button
            design="primary"
            onClick={() => {
              handler();
              onClose();
            }}
          >
            Yes, Proceed.
          </Button>
        </div>
      </div>
    ),
  });
};

const parseRedirectUrl = (url = '') => {
  const newUrl = url
    .replace('http://localhost:3000', '')
    .replace('https://www.elevolt.co.ke', '');

  return encodeURIComponent(newUrl);
};

const getTenantCookie = () =>
  cookie.get('elv_tenant', {
    domain: '.elevolt.co.ke',
  });

const getAuthCookie = () =>
  cookie.get('elv_token', {
    domain: '.elevolt.co.ke',
  });

const parseStatus = (status) => {
  switch (status) {
    case 'pending approval':
      return 'Pending';
    case 'pending user action':
      return 'Awaiting User';

    default:
      return status;
  }
};

const parseUserWebsite = (url) => {
  if (!url.startsWith('http')) {
    return `https://${url}`;
  }

  return url;
};

export const isNumeric = (str) => {
  if (typeof str !== 'string') return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
};

export const getApplicationStatus = (status) => {
  switch (status) {
    case 'new':
      return 'Under Review';

    case 'rejected':
      return 'Submitted';

    default:
      return status;
  }
};

export const transformErrors = (errors) =>
  errors.map((error) => {
    // use error messages from JSON schema if any
    if (
      error.schema &&
      error.schema.messages &&
      error.schema.messages[error.name]
    ) {
      return {
        ...error,
        message: error.schema.messages[error.name],
      };
    }
    if (
      !error.schema ||
      !error.schema.messages ||
      !error.schema.messages[error.name]
    ) {
      let { message } = error;

      if (message === 'is a required property') {
        message = `This ${message}`;
      }

      return {
        ...error,
        message,
      };
    }

    return error;
  });

const getMouseClickXY = (e) => {
  const containerX = e.target.offsetLeft;
  const containerY = e.target.offsetTop;

  var coord = {
    X: null,
    Y: null,
  };

  var isTouchSupported = 'ontouchstart' in window;

  if (isTouchSupported) {
    // for touch devices
    coord.X = e.clientX - containerX;
    coord.Y = e.clientY - containerY;
    return coord;
  } else if (e.offsetX || e.offsetX == 0) {
    //for webkit browser like safari and chrome
    coord.X = e.offsetX;
    coord.Y = e.offsetY;
    return coord;
  } else if (e.layerX || e.layerX == 0) {
    // for mozilla firefox
    coord.X = e.layerX;
    coord.Y = e.layerY;
    return coord;
  }
};

const parseBlogHtml = (content = '') => {
  if (typeof content.replaceAll !== 'function') return '';

  return content.replaceAll(
    'https://blog.elevolt.co.ke',
    'https://elevolt.co.ke/blog'
  );
};

const getSlot = (index, size) => {
  const slots = {
    4: {
      sm: 6654788510,
      lg: 2863717310,
    },
    // 8: {
    // 	sm: 6270413859,
    // 	lg: 3405820630,
    // },
    12: {
      sm: 6080073441,
      lg: 3757162439,
    },
  };

  if (!slots[index]) return;

  return slots[index][size];
};

const getJobPostValidThrough = (post, days = 30) => {
  if (!post) return null;

  if (post.updated_at) {
    return addToDate(post.updated_at, days);
  }

  return addToDate(post.created_at, days);
};

export {
  parseUnderScore,
  capitalize,
  hasCompletedProfile,
  isFeaturedPost,
  isNewPost,
  excerpt,
  userCanEditPost,
  parseDash,
  useMobileScreenWidth,
  httpAgent,
  getOptions,
  useOnScreen,
  useLgScreenWidth,
  useXLScreenWidth,
  getQueryString,
  getYearsInRange,
  trackEvent,
  isInSplit,
  getFromDate,
  formatDate,
  addToDate,
  getRatingStrokeColor,
  getApplicationStatusBg,
  getRatingBg,
  getJobPostStatusBg,
  getPixeRatio,
  buildThresholdList,
  initConfirmAlert,
  parseRedirectUrl,
  getOfferStatusBg,
  getTenantCookie,
  getItemStatusBg,
  parseStatus,
  parseValueForUnknown,
  getAuthCookie,
  parseUserWebsite,
  getMouseClickXY,
  parseBlogHtml,
  getSlot,
  getJobPostValidThrough,
};
