import axios from 'axios';
import Router from 'next/router';
import { toast } from 'react-hot-toast';
import isEmpty from 'lodash.isempty';
import cookie from 'js-cookie';
import * as actions from './actions';
import { validateFormDataFailure } from './validatorActions';
import * as httpActions from './httpActions';
import { apiUrl } from '../utils/Config';
import { login, logout } from '../utils/auth';
import {
  getApplicationRequest,
  getApplicationsRequest,
} from './applicationActions';
import {
  getUpdatesFailure,
  getUpdatesRequest,
  getUpdatesSuccess,
} from './updateActions';

import {
  getJobpostRequest,
  getJobpostsRequest,
  getRelatedJobpostsRequest,
} from './jobpostActions';
import { getAuthCookie, getTenantCookie, httpAgent } from '../helpers';
import { getTasksRequest } from './taskActions';

axios.defaults.headers.Authorization = `Bearer ${cookie.get('elv_token')}`;

export function registerRequest(creds) {
  return {
    type: actions.REGISTER_REQUEST,
    isAuthenticated: false,
    creds,
  };
}

export function registerSuccess(user) {
  return {
    type: actions.REGISTER_SUCCESS,
    isAuthenticated: true,
    authToken: user.token,
    user,
    errors: {},
  };
}

export function registerFailure(errors) {
  return {
    type: actions.REGISTER_FAILURE,
    isAuthenticated: false,
    errors,
  };
}

export function loginRequest(creds) {
  return {
    type: actions.LOGIN_REQUEST,
    isAuthenticated: false,
    creds,
  };
}

export function loginSuccess(data) {
  return {
    type: actions.LOGIN_SUCCESS,
    isAuthenticated: true,
    authToken: data.token,
    user: data.user,
  };
}

export function loginFailure(errors) {
  return {
    type: actions.LOGIN_FAILURE,
    isAuthenticated: false,
    errors,
  };
}

export function showLoginModal() {
  return {
    type: actions.SHOW_LOGIN_MODAL,
  };
}

export function hideLoginModal() {
  return {
    type: actions.HIDE_LOGIN_MODAL,
  };
}

export function forgotPasswordRequest(creds) {
  return {
    type: actions.FORGOT_PASSWORD_REQUEST,
    creds,
  };
}

export function forgotPasswordSuccess() {
  return {
    type: actions.FORGOT_PASSWORD_SUCCESS,
  };
}

export function forgotPasswordFailure(errors) {
  return {
    type: actions.FORGOT_PASSWORD_FAILURE,
    errors,
  };
}

export function updatePasswordRequest(creds) {
  return {
    type: actions.UPDATE_PASSWORD_REQUEST,
    creds,
  };
}

export function updatePasswordSuccess() {
  return {
    type: actions.UPDATE_PASSWORD_SUCCESS,
  };
}

export function updatePasswordFailure(errors) {
  return {
    type: actions.UPDATE_PASSWORD_FAILURE,
    error: errors,
  };
}

export function updateUserRequest() {
  return {
    type: actions.UPDATE_USER_REQUEST,
  };
}

export function updateUserSuccess(user) {
  return {
    type: actions.UPDATE_USER_SUCCESS,
    user,
  };
}

export function updateUserFailure(errors) {
  return {
    type: actions.UPDATE_USER_FAILURE,
    error: errors,
  };
}

export function createUserRequest(user) {
  return {
    type: actions.CREATE_USER_REQUEST,
    user,
  };
}

export function createUserSuccess(user) {
  return {
    type: actions.CREATE_USER_SUCCESS,
    user,
  };
}

export function createUserFailure(errors) {
  return {
    type: actions.CREATE_USER_FAILURE,
    error: errors,
  };
}

export function logOutRequest() {
  return {
    type: actions.LOGOUT_REQUEST,
    isAuthenticated: false,
  };
}

export function getMeRequest() {
  return {
    type: actions.GET_ME_REQUEST,
  };
}

export function getMeRequestSuccess(user) {
  return {
    type: actions.GET_ME_SUCCESS,
    user,
  };
}

export function getMeRequestFailure(errors) {
  return {
    type: actions.GET_ME_FAILURE,
    error: errors,
  };
}

export function getUsersRequest() {
  return {
    type: actions.GET_USERS_REQUEST,
  };
}

export function getUsersRequestSuccess(users) {
  return {
    type: actions.GET_USERS_SUCCESS,
    users,
  };
}

export function getUsersRequestFailure(errors) {
  return {
    type: actions.GET_USERS_FAILURE,
    error: errors,
  };
}

export function registerUser(
  creds,
  redirect,
  registerLink = '',
  returnTo,
  invitation,
  tid
) {
  let extLink = registerLink;

  // Truncate additional slash if exists
  if (!registerLink.startsWith('/auth/')) {
    extLink = `auth${extLink}`;
  } else {
    extLink = registerLink.substring(1);
  }

  const message = invitation
    ? 'Congratulations! You have successfully accepted the invitation.'
    : 'Congratulations! You have signed up successfully.';

  const config = {
    method: 'post',
    url: `${apiUrl}auth/register`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: creds,
  };

  return function (dispatch) {
    dispatch(registerRequest());
    dispatch(httpActions.startHttpRequest());

    if (registerLink) {
      config.url = apiUrl + extLink;
    }

    if (tid) {
      config.headers = { ...config.headers, 'X-Company': tid };
    }

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());
        if (response.data.token) {
          dispatch(registerSuccess(response.data));
          let returnUrl = returnTo;

          if (isEmpty(returnUrl)) {
            if (!isEmpty(response.data.user.tenant)) {
              returnUrl = `hr/${response.data.user.tenant.name}`;
            } else {
              returnUrl = 'dash';
            }
          }

          login({
            user: response.data,
            redirect,
            message,
            goto: returnUrl,
          });
        } else {
          dispatch(registerFailure(response.data));
          dispatch(validateFormDataFailure(response.data));
        }
      })
      .catch((error) => {
        dispatch(
          validateFormDataFailure(
            error.response && error.response.data && error.response.data.errors
          )
        );
        dispatch(
          httpActions.httpRequestFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
        console.error(error);
      });
  };
}

export function loginUser(creds, returnTo, redirect, updateStore = true) {
  const config = {
    method: 'post',
    url: `${apiUrl}auth/login`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: creds,
  };

  return function (dispatch, getState) {
    const state = getState();
    const authUser = state.auth.user;

    if (authUser && authUser.tenant_id) {
      config.headers = {
        ...config.headers,
        'X-Company': creds.tenant,
      };
    }

    dispatch(loginRequest());
    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());

        if (response.data.user && response.data.token) {
          updateStore && dispatch(loginSuccess(response.data));

          let returnUrl = returnTo;

          if (isEmpty(returnUrl)) {
            if (!isEmpty(response.data.user.tenant)) {
              returnUrl = `hr/${response.data.user.tenant.name}`;
            } else {
              returnUrl = 'dash';
            }
          }

          login({ user: response.data, goto: returnUrl, redirect });

          return response.data;
        }
        dispatch(loginFailure(response.data));
        dispatch(validateFormDataFailure(response.data));
      })
      .catch((error) => {
        dispatch(
          httpActions.httpRequestFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
        console.error(error);
      });
  };
}

export function changePassword(creds) {
  const config = {
    method: 'post',
    url: `${apiUrl}auth/password/forgot`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: creds,
  };

  return function (dispatch) {
    dispatch(forgotPasswordRequest());
    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());

        if (response.data) {
          dispatch(forgotPasswordSuccess(response.data));

          if (
            response.data &&
            response.data.message === 'Password reset link sent successfully!'
          ) {
            toast.success(
              "We've sent a password reset link to your email address.",
              'Success'
            );
          }

          return response.data;
        }
      })
      .catch((error) => {
        dispatch(
          httpActions.httpRequestFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
        console.error(error);
      });
  };
}

export function updatePassword(creds) {
  const config = {
    method: 'post',
    url: `${apiUrl}auth/password/update`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: creds,
  };

  return function (dispatch) {
    dispatch(updatePasswordRequest());
    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());

        if (response.data) {
          dispatch(updatePasswordSuccess(response.data));

          if (
            response.data &&
            response.data.message === 'Password reset successfully!'
          ) {
            toast.success(response.data.message);

            Router.push('/login');
          }

          return response.data;
        }
      })
      .catch((error) => {
        dispatch(
          httpActions.httpRequestFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
        console.error(error);
      });
  };
}

export function updateUser(user) {
  const config = {
    method: 'post',
    url: `${apiUrl}users/${user.uid}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: user,
  };

  return function (dispatch, getState) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getState().auth.authToken}`,
    };

    const authUser = getState().auth.user;

    if (authUser && authUser.tenant_id) {
      config.headers = {
        ...config.headers,
        'X-Company': authUser.tenant_id,
      };
    }

    dispatch(updateUserRequest());
    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());

        if (response.data.user) {
          dispatch(updateUserSuccess(response.data.user));

          return response.data.user;
        }
        dispatch(validateFormDataFailure(response.data));
      })
      .catch((error) => {
        dispatch(validateFormDataFailure(error.response?.data?.errors));
        dispatch(
          httpActions.httpRequestFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
        console.error(error);
      });
  };
}

export function updateUserSettings(id, settings) {
  const config = {
    method: 'post',
    url: `${apiUrl}users/${id}/settings`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: { settings },
  };

  return function (dispatch) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getAuthCookie()}`,
    };

    dispatch(updateUserRequest());
    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());

        if (response.data.user) {
          dispatch(updateUserSuccess(response.data.user));

          return response.data.user;
        }
        dispatch(validateFormDataFailure(response.data));
      })
      .catch((error) => {
        dispatch(validateFormDataFailure(error.response?.data?.errors));
        dispatch(
          httpActions.httpRequestFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
        console.error(error);
      });
  };
}

export function getMe(dispatchActions = true) {
  let token = cookie.get('elv_token');
  const config = {
    method: 'post',
    url: `${apiUrl}auth/me`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: {},
  };

  return function (dispatch, getState) {
    if (!token) {
      token = getState().auth.authToken;
    }

    config.headers = { ...config.headers, Authorization: `Bearer ${token}` };

    const authUser = getState().auth.user;

    if (authUser && !isEmpty(authUser.tenant_id)) {
      config.headers = { ...config.headers, 'X-Company': authUser.tenant_id };
    }

    dispatchActions && dispatch(getMeRequest());
    dispatchActions && dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatchActions && dispatch(httpActions.endHttpRequest());

        if (response.data) {
          dispatchActions && dispatch(getMeRequestSuccess(response.data));

          return response.data;
        }
      })
      .catch((error) => {
        dispatchActions &&
          dispatch(
            httpActions.httpRequestFailure(
              error.response &&
                (error.response.data.error ||
                  error.response.data.errors ||
                  error.response.data.message)
            )
          );
        console.error(error);
      });
  };
}

export function logOutUser() {
  return function (dispatch) {
    // stored data
    dispatch(hideLoginModal());
    dispatch(logOutRequest());
    dispatch(getApplicationRequest());
    dispatch(getApplicationsRequest());
    dispatch(getJobpostRequest());
    dispatch(getJobpostsRequest());
    dispatch(getRelatedJobpostsRequest());
    dispatch(getUpdatesRequest());
    dispatch(getTasksRequest());
    dispatch(getApplicationsRequest());

    logout();
  };
}

export function verify({ link }) {
  let extLink = link;

  // Truncate additional slash if exists
  if (!link.startsWith('/auth/')) {
    extLink = `auth${extLink}`;
  } else {
    extLink = link.substring(1);
  }

  const config = {
    method: 'get',
    url: apiUrl + extLink,
  };

  return function (dispatch, getState) {
    return doAxiosCall(config, dispatch, getState);
  };
}

export function resendVerification() {
  const config = {
    method: 'get',
    url: `${apiUrl}auth/verify/resend`,
  };

  return function (dispatch, getState) {
    return doAxiosCall(config, dispatch, getState);
  };
}

export function subscribeMe(filters) {
  const config = {
    url: `${apiUrl}list`,
    data: { ...filters },
  };

  return function (dispatch, getState) {
    return doAxiosCall(config, dispatch, getState);
  };
}

export function getBoardUser(id) {
  const config = {
    method: 'get',
    url: `${apiUrl}board/${id}/user`,
  };

  return function (dispatch, getState) {
    return doAxiosCall(config, dispatch, getState);
  };
}

export function createEducation(education, userId) {
  const config = {
    url: `${apiUrl}user/${userId}/education`,
    data: { ...education },
  };

  return function (dispatch, getState) {
    return doAxiosCall(config, dispatch, getState);
  };
}

export function editEducation(education) {
  const config = {
    url: `${apiUrl}education/${education.eid}`,
    data: { ...education },
  };

  return function (dispatch, getState) {
    return doAxiosCall(config, dispatch, getState);
  };
}

export function getEducation(userId) {
  const config = {
    method: 'get',
    url: `${apiUrl}user/${userId}/education`,
  };

  return function (dispatch, getState) {
    return doAxiosCall(config, dispatch, getState);
  };
}

export function deleteEducation(education) {
  const config = {
    method: 'delete',
    url: `${apiUrl}education/${education.eid}`,
  };

  return function (dispatch, getState) {
    return doAxiosCall(config, dispatch, getState);
  };
}

export function createExperience(experience, userId) {
  const config = {
    url: `${apiUrl}user/${userId}/experience`,
    data: { ...experience },
  };

  return function (dispatch, getState) {
    return doAxiosCall(config, dispatch, getState);
  };
}

export function editExperience(experience) {
  const config = {
    url: `${apiUrl}experience/${experience.eid}`,
    data: { ...experience },
  };

  return function (dispatch, getState) {
    return doAxiosCall(config, dispatch, getState);
  };
}

export function getExperience(userId) {
  const config = {
    method: 'get',
    url: `${apiUrl}user/${userId}/experience`,
  };

  return function (dispatch, getState) {
    return doAxiosCall(config, dispatch, getState);
  };
}

export function deleteExperience(experience) {
  const config = {
    method: 'delete',
    url: `${apiUrl}experience/${experience.eid}`,
  };

  return function (dispatch, getState) {
    return doAxiosCall(config, dispatch, getState);
  };
}

export function createCertification(certification, userId) {
  const config = {
    url: `${apiUrl}user/${userId}/certification`,
    data: { ...certification },
  };

  return function (dispatch, getState) {
    return doAxiosCall(config, dispatch, getState);
  };
}

export function editCertification(certification) {
  const config = {
    method: 'post',
    url: `${apiUrl}certification/${certification.cid}`,
    data: { ...certification },
  };

  return function (dispatch, getState) {
    return doAxiosCall(config, dispatch, getState);
  };
}

export function getCertification(userId) {
  const config = {
    method: 'get',
    url: `${apiUrl}user/${userId}/certification`,
  };

  return function (dispatch, getState) {
    return doAxiosCall(config, dispatch, getState);
  };
}

export function deleteCertification(certification) {
  const config = {
    method: 'delete',
    url: `${apiUrl}certification/${certification.cid}`,
  };

  return function (dispatch, getState) {
    return doAxiosCall(config, dispatch, getState);
  };
}

export function getUserTenants(creds) {
  const config = {
    url: `${apiUrl}auth/login/precheck`,
    data: creds,
  };

  return function (dispatch, getState) {
    return doAxiosCall(config, dispatch, getState);
  };
}

export function getUpdatesForUser(
  user,
  opts = { paginate: 0, page: 0, status: 'any' }
) {
  const params = new URLSearchParams(opts);
  const config = {
    method: 'get',
    url: `${apiUrl}user/${user.uid}/updates?${params}`,
  };

  return function (dispatch) {
    return doAxiosCall(config, dispatch, {
      preRequest: {
        action: getUpdatesRequest,
      },
      onSuccess: {
        action: getUpdatesSuccess,
      },
      onError: {
        action: getUpdatesFailure,
      },
    });
  };
}

// eslint-disable-next-line no-unused-vars
export function updateEvent(event, action = 'update') {
  const config = {
    data: event,
  };

  return function (dispatch, getState) {
    const authUser = getState().auth.user;

    config.url = `${apiUrl}user/${authUser.uid}/updates/${event.id}`;

    if (action === 'delete') {
      config.method = 'delete';
    }

    return doAxiosCall(config, dispatch);
  };
}

export function createSignature(file) {
  const config = {
    data: file,
  };

  return function (dispatch, getState) {
    const authUser = getState().auth.user;

    config.url = `${apiUrl}user/${authUser.uid}/signatures`;

    return doAxiosCall(config, dispatch, getState);
  };
}

export function getSignatures() {
  const config = {
    method: 'get',
  };

  return function (dispatch, getState) {
    const authUser = getState().auth.user;

    config.url = `${apiUrl}user/${authUser.uid}/signatures`;

    return doAxiosCall(config, dispatch, getState);
  };
}

export function deleteSignature(id) {
  const config = {
    method: 'delete',
  };

  return function (dispatch, getState) {
    const authUser = getState().auth.user;

    config.url = `${apiUrl}user/${authUser.uid}/signatures/${id}`;

    return doAxiosCall(config, dispatch, getState);
  };
}

export function enableTFA(data) {
  const config = {
    method: 'post',
    data,
  };

  return function (dispatch, getState) {
    config.url = `${apiUrl}auth/2fa/enable`;

    return doAxiosCall(config, dispatch, getState);
  };
}

export function disableTFA() {
  const config = {
    method: 'post',
  };

  return function (dispatch, getState) {
    config.url = `${apiUrl}auth/2fa/disable`;

    return doAxiosCall(config, dispatch, getState);
  };
}

export function verifyTFA(data) {
  const config = {
    method: 'post',
    data,
  };

  return function (dispatch, getState) {
    config.url = `${apiUrl}auth/2fa/verify`;

    return doAxiosCall(config, dispatch, getState);
  };
}

export function getTFAQRCode() {
  const config = {
    method: 'get',
  };

  return function (dispatch, getState) {
    config.url = `${apiUrl}auth/2fa`;

    return doAxiosCall(config, dispatch, getState);
  };
}

export function deleteUserAccount(user) {
  const config = {
    method: 'post',
  };

  return function (dispatch, getState) {
    config.url = `${apiUrl}users/${user}/account/delete`;

    return doAxiosCall(config, dispatch, getState);
  };
}

const doAxiosCall = (extraConfig, dispatch, props = {}) => {
  const config = {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  dispatch(httpActions.startHttpRequest());

  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${getAuthCookie()}`,
    'X-Company': getTenantCookie(),
  };

  if (process.env.NODE_ENV === 'development') {
    config.httpsAgent = httpAgent();
  }

  props.preRequest?.action && dispatch(props.preRequest?.action);

  return axios({ ...config, ...extraConfig })
    .then((response) => {
      dispatch(httpActions.endHttpRequest());

      if (response.data) {
        props.onSuccess?.action &&
          dispatch(props.onSuccess.action(response.data?.data));
        return response.data;
      }
      dispatch(validateFormDataFailure(response.data));
    })
    .catch((error) => {
      props.onError?.action &&
        dispatch(
          props.onError?.action(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );

      dispatch(
        httpActions.httpRequestFailure(
          error.response &&
            (error.response.data.error ||
              error.response.data.errors ||
              error.response.data.message)
        )
      );
      console.error(error);
    });
};
