import { differenceInCalendarDays } from 'date-fns';

export const isNewPost = (date) => {
	const today = new Date();
	const postdate = new Date(date);

	return differenceInCalendarDays(postdate, today) < 3;
};
export const isNewApplication = (date) => {
	const today = new Date();
	const postdate = new Date(date);

	return differenceInCalendarDays(postdate, today) < 1;
};

export const isFeaturedPost = (plan) => plan === 'featured';

export const parseJobPostStatus = (status) => {
	if (status === 'pre-approved') {
		return 'Pending Approval';
	}
	return status;
};

export const getOwnerName = (post) => {
	if (!post.owner) return '';

	if (post.owner && post.owner.display_name) {
		return post.owner.display_name;
	}

	return post.owner.name;
};
