import * as actions from './actions';

export function startHttpRequest() {
	return {
		type: actions.START_HTTP_REQUEST,
	};
}

export function endHttpRequest() {
	return {
		type: actions.END_HTTP_REQUEST,
	};
}

export function httpRequestFailure(errors) {
	return {
		type: actions.HTTP_REQUEST_FAILURE,
		errors,
	};
}

export function startHttpUploadRequest() {
	return {
		type: actions.START_HTTP_UPLOAD_REQUEST,
	};
}

export function endHttpUploadRequest() {
	return {
		type: actions.END_HTTP_UPLOAD_REQUEST,
	};
}
