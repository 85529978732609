export const qualifications = [
	{
		title: 'Doctorate',
	},
	{
		title: 'Masters',
	},
	{
		title: 'Bachelor',
	},
	{
		title: 'Diploma',
	},
	{
		title: 'Certificate',
	},
	{
		title: 'KCSE',
	},
	{
		title: 'KCPE',
	},
	{ title: 'Other' },
];
