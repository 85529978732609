import * as actions from '../actions/actions';
import initialState from './initialState';

export default function applicationReducer(
  state = initialState.applications,
  action
) {
  switch (action.type) {
    case actions.GET_APPLICATIONS_SUCCESS:
      return { ...state, applications: action.applications };

    case actions.GET_APPLICATIONS_REQUEST:
      return { ...state };

    case actions.GET_APPLICATIONS_FAILURE:
      return {
        ...state,
        applications: [],
        errors: action.errors,
      };

    case actions.GET_APPLICATION_SUCCESS:
      return { ...state, application: action.application };

    case actions.GET_APPLICATION_REQUEST:
      return {
        ...state,
        application: {},
        errors: {},
      };
    case actions.GET_APPLICATION_FAILURE:
      return {
        ...state,
        application: {},
        errors: action.errors,
      };
    case actions.CREATE_APPLICATION_SUCCESS:
      return { ...state, application: action.application };

    case actions.CREATE_APPLICATION_REQUEST:
      return { ...state };

    case actions.CREATE_APPLICATION_FAILURE:
      return {
        ...state,
        application: {},
        errors: action.errors,
      };
    case actions.UPDATE_APPLICATION_SUCCESS:
      return { ...state, application: action.application };

    case actions.UPDATE_APPLICATION_REQUEST:
      return { ...state };

    case actions.UPDATE_APPLICATION_FAILURE:
      return {
        ...state,
        application: {},
        errors: action.errors,
      };

    default:
      return state;
  }
}
