export default {
	auth: {
		user: {},
		authToken: '',
		users: [],
		errors: {
			register: {},
			login: {},
		},
		showLoginModal: false,
	},
	tenant: {},
	tasks: {},
	updates: {},
	splitio: {},
	http: {
		isUploading: false,
		isFetching: false,
		error: {},
	},
	validator: {
		isValid: false,
		errors: {},
	},
	jobposts: {
		jobposts: [],
		jobpost: {},
		related: [],
	},
	chat: {
		messages: [],
	},
	applications: {
		applications: [],
		application: {},
	},
	storage: {},
};
