import React from 'react';
import PropTypes from 'prop-types';

function Loader(props) {
  const { size = '' } = props;
  return (
    <div className={`loader-wrapper ${size}`}>
      <div className={`loader ${size}`}>Loading...</div>
    </div>
  );
}

Loader.propTypes = {
  size: PropTypes.string,
};

export function FullLoader(props) {
  const { size = '', fixed = true, message = null } = props;
  return (
    <div
      className={`${
        fixed ? 'fixed' : 'absolute'
      } full-loader top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-white bg-opacity-70 z-[9999]`}
    >
      <div className={`loader-wrapper ${size}`}>
        <div className={`loader ${size}`}>Loading...</div>
      </div>
      {message && <p className="mt-3 font-medium text-gray-700">{message}</p>}
    </div>
  );
}

FullLoader.propTypes = {
  size: PropTypes.string,
  message: PropTypes.string,
  fixed: PropTypes.bool,
};

export default Loader;
