export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const GET_ME_REQUEST = 'GET_ME_REQUEST';
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const GET_ME_FAILURE = 'GET_ME_FAILURE';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT = 'LOGOUT';

export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL';
export const HIDE_LOGIN_MODAL = 'HIDE_LOGIN_MODAL';

export const VALIDATE_FORM_DATA_REQUEST = 'VALIDATE_FORM_DATA_REQUEST';
export const VALIDATE_FORM_DATA_FAILURE = 'VALIDATE_FORM_DATA_FAILURE';
export const VALIDATE_FORM_DATA_SUCCESS = 'VALIDATE_FORM_DATA_SUCCESS';

export const START_HTTP_REQUEST = 'START_HTTP_REQUEST';
export const END_HTTP_REQUEST = 'END_HTTP_REQUEST';
export const HTTP_REQUEST_FAILURE = 'HTTP_REQUEST_FAILURE';

export const START_HTTP_UPLOAD_REQUEST = 'START_HTTP_UPLOAD_REQUEST';
export const END_HTTP_UPLOAD_REQUEST = 'END_HTTP_UPLOAD_REQUEST';

export const GET_JOBPOSTS_REQUEST = 'GET_JOBPOSTS_REQUEST';
export const GET_JOBPOSTS_SUCCESS = 'GET_JOBPOSTS_SUCCESS';
export const GET_JOBPOSTS_FAILURE = 'GET_JOBPOSTS_FAILURE';

export const GET_RELATED_JOBPOSTS_REQUEST = 'GET_RELATED_JOBPOSTS_REQUEST';
export const GET_RELATED_JOBPOSTS_SUCCESS = 'GET_RELATED_JOBPOSTS_SUCCESS';
export const GET_RELATED_JOBPOSTS_FAILURE = 'GET_RELATED_JOBPOSTS_FAILURE';

export const GET_JOBPOST_REQUEST = 'GET_JOBPOST_REQUEST';
export const GET_JOBPOST_SUCCESS = 'GET_JOBPOST_SUCCESS';
export const GET_JOBPOST_FAILURE = 'GET_JOBPOST_FAILURE';

export const CREATE_JOBPOST_REQUEST = 'CREATE_JOBPOST_REQUEST';
export const CREATE_JOBPOST_SUCCESS = 'CREATE_JOBPOST_SUCCESS';
export const CREATE_JOBPOST_FAILURE = 'CREATE_JOBPOST_FAILURE';

export const UPDATE_JOBPOST_REQUEST = 'UPDATE_JOBPOST_REQUEST';
export const UPDATE_JOBPOST_SUCCESS = 'UPDATE_JOBPOST_SUCCESS';
export const UPDATE_JOBPOST_FAILURE = 'UPDATE_JOBPOST_FAILURE';

export const GET_APPLICATIONS_REQUEST = 'GET_APPLICATIONS_REQUEST';
export const GET_APPLICATIONS_SUCCESS = 'GET_APPLICATIONS_SUCCESS';
export const GET_APPLICATIONS_FAILURE = 'GET_APPLICATIONS_FAILURE';

export const GET_APPLICATION_REQUEST = 'GET_APPLICATION_REQUEST';
export const GET_APPLICATION_SUCCESS = 'GET_APPLICATION_SUCCESS';
export const GET_APPLICATION_FAILURE = 'GET_APPLICATION_FAILURE';

export const CREATE_APPLICATION_REQUEST = 'CREATE_APPLICATION_REQUEST';
export const CREATE_APPLICATION_SUCCESS = 'CREATE_APPLICATION_SUCCESS';
export const CREATE_APPLICATION_FAILURE = 'CREATE_APPLICATION_FAILURE';

export const UPDATE_APPLICATION_REQUEST = 'UPDATE_APPLICATION_REQUEST';
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS';
export const UPDATE_APPLICATION_FAILURE = 'UPDATE_APPLICATION_FAILURE';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';

export const GET_CHATS_REQUEST = 'GET_CHATS_REQUEST';
export const GET_CHATS_SUCCESS = 'GET_CHATS_SUCCESS';
export const GET_CHATS_FAILURE = 'GET_CHATS_FAILURE';

export const GET_CHAT_REQUEST = 'GET_CHAT_REQUEST';
export const GET_CHAT_SUCCESS = 'GET_CHAT_SUCCESS';
export const GET_CHAT_FAILURE = 'GET_CHAT_FAILURE';

export const CREATE_CHAT_REQUEST = 'CREATE_CHAT_REQUEST';
export const CREATE_CHAT_SUCCESS = 'CREATE_CHAT_SUCCESS';
export const CREATE_CHAT_FAILURE = 'CREATE_CHAT_FAILURE';

export const UPDATE_CHAT_REQUEST = 'UPDATE_CHAT_REQUEST';
export const UPDATE_CHAT_SUCCESS = 'UPDATE_CHAT_SUCCESS';
export const UPDATE_CHAT_FAILURE = 'UPDATE_CHAT_FAILURE';

export const GET_TASKS_REQUEST = 'GET_TASKS_REQUEST';
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const GET_TASKS_FAILURE = 'GET_TASKS_FAILURE';

export const GET_TASK_REQUEST = 'GET_TASK_REQUEST';
export const GET_TASK_SUCCESS = 'GET_TASK_SUCCESS';
export const GET_TASK_FAILURE = 'GET_TASK_FAILURE';

export const CREATE_TASK_REQUEST = 'CREATE_TASK_REQUEST';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const CREATE_TASK_FAILURE = 'CREATE_TASK_FAILURE';

export const UPDATE_TASK_REQUEST = 'UPDATE_TASK_REQUEST';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_FAILURE = 'UPDATE_TASK_FAILURE';

export const GET_UPDATES_REQUEST = 'GET_UPDATES_REQUEST';
export const GET_UPDATES_SUCCESS = 'GET_UPDATES_SUCCESS';
export const GET_UPDATES_FAILURE = 'GET_UPDATES_FAILURE';

export const GET_UPDATE_REQUEST = 'GET_UPDATE_REQUEST';
export const GET_UPDATE_SUCCESS = 'GET_UPDATE_SUCCESS';
export const GET_UPDATE_FAILURE = 'GET_UPDATE_FAILURE';

export const CREATE_UPDATE_REQUEST = 'CREATE_UPDATE_REQUEST';
export const CREATE_UPDATE_SUCCESS = 'CREATE_UPDATE_SUCCESS';
export const CREATE_UPDATE_FAILURE = 'CREATE_UPDATE_FAILURE';

export const UPDATE_UPDATE_REQUEST = 'UPDATE_UPDATE_REQUEST';
export const UPDATE_UPDATE_SUCCESS = 'UPDATE_UPDATE_SUCCESS';
export const UPDATE_UPDATE_FAILURE = 'UPDATE_UPDATE_FAILURE';
