import { combineReducers } from 'redux';
import auth from './authReducer';
import validator from './validatorReducer';
import http from './httpReducer';
import jobposts from './jobpostsReducer';
import chat from './chatReducer';
import applications from './applicationsReducer';
import storage from './storageReducer';
import tenant from './tenantReducer';
import tasks from './tasksReducer';
import updates from './updatesReducer';
// import { splitReducer } from '@splitsoftware/splitio-redux';

const rootReducer = combineReducers({
	http,
	// splitio: splitReducer,
	auth,
	validator,
	jobposts,
	applications,
	storage,
	chat,
	tenant,
	tasks,
	updates,
});

export default rootReducer;
