import * as actions from '../actions/actions';
import initialState from './initialState';

export default function chatReducer(state = initialState.chat, action) {
	switch (action.type) {
	case actions.GET_CHATS_SUCCESS:
		return { ...state, chats: action.chats };

	case actions.GET_CHATS_REQUEST:
		return {
			...state,
			chats: [],
			chat: {},
			related: [],
		};

	case actions.GET_CHATS_FAILURE:
		return {
			...state,
			chats: [],
			errors: action.errors,
		};

	case actions.GET_CHAT_SUCCESS:
		return { ...state, chat: action.chat };
	case actions.GET_CHAT_REQUEST:
		return {
			...state,
			chat: {},
			errors: {},
		};
	case actions.GET_CHAT_FAILURE:
		return {
			...state,
			chat: {},
			errors: action.errors,
		};
	case actions.CREATE_CHAT_SUCCESS:
		return { ...state, chat: action.chat };

	case actions.CREATE_CHAT_REQUEST:
		return { ...state };

	case actions.CREATE_CHAT_FAILURE:
		return {
			...state,
			chat: {},
			errors: action.errors,
		};
	case actions.UPDATE_CHAT_SUCCESS:
		return { ...state, chat: action.chat };

	case actions.UPDATE_CHAT_REQUEST:
		return { ...state };

	case actions.UPDATE_CHAT_FAILURE:
		return {
			...state,
			chat: {},
			errors: action.errors,
		};

	default:
		return state;
	}
}
