import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import NoSSR from '../components/common/no-ssr';
import withReduxStore from '../lib/with-redux-store';
import { useStore } from '../lib/store';
import { pageview } from '../lib/gtag';

import '@fortawesome/fontawesome-svg-core/styles.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-datepicker/dist/react-datepicker.min.css';
import '../styles/index.css';

import { Poppins, Roboto } from '@next/font/google';

const poppins = Poppins({
  subsets: ['latin'],
  variable: '--font-poppins',
  weight: ['300', '400', '500', '600', '700'],
});

const roboto = Roboto({
  subsets: ['latin'],
  variable: '--font-roboto',
  weight: ['300', '400', '500', '700'],
});

const Footer = dynamic(() => import('../components/footer').then((mod) => mod));

const ReactTooltip = dynamic(() => import('react-tooltip').then((mod) => mod));

const Toaster = dynamic(() =>
  import('react-hot-toast').then((mod) => mod.Toaster)
);

const LoginModal = dynamic(() =>
  import('../components/auth-forms/loginModal').then((mod) => mod)
);

const Header = dynamic(() => import('../components/header').then((mod) => mod));

export function MyApp(props = {}) {
  const { Component, pageProps, err, reduxStore, treatments } = props;

  const store = useStore(reduxStore);
  const persistor = persistStore(store, {}, () => {
    persistor.persist();
  });
  const router = useRouter();

  useEffect(() => {
    // if (
    // 	typeof loadReCaptcha !== 'undefined' &&
    // 	(router.pathname === '/login' ||
    // 		router.pathname === '/register' ||
    // 		router.pathname === '/contact' ||
    // 		router.pathname === '/post')
    // ) {
    // 	import('react-recaptcha-v3').then((rec) => {
    // 		rec.loadReCaptcha('6LdFrPkUAAAAAMfzDSPuO67JSWtY34CgE94Mfdhj');
    // 	});
    // }

    /** reset google adsense styling */
    const nextWrapper = document.getElementById('__next');
    const wrapper = document.getElementById('page-wrapper');

    // eslint-disable-next-line no-unused-vars
    const mutationCallback = (mutations, observer) => {
      wrapper.style.height = '';
      wrapper.style.minHeight = '';
    };

    const nextObserver = new MutationObserver(mutationCallback);
    const observer = new MutationObserver(mutationCallback);

    nextObserver.observe(nextWrapper, {
      attributes: true,
      attributeFilter: ['style'],
    });
    observer.observe(wrapper, {
      attributes: true,
      attributeFilter: ['style'],
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const handleRouteChange = (url) => {
      pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Provider store={store}>
        <div
          id="page-wrapper"
          className={`${poppins.variable} ${roboto.variable} page-wrapper flex flex-col w-full h-full min-h-screen`}
        >
          <Header route={router.route} treatments={treatments} />
          <PersistGate loading={null} persistor={persistor}>
            {() => (
              <>
                <LoginModal />
                <Component {...pageProps} treatments={treatments} err={err} />
              </>
            )}
          </PersistGate>
          <Footer />
          <NoSSR>
            <Toaster
              containerStyle={{
                zIndex: 999999999,
              }}
              reverseOrder
              toastOptions={{
                duration: 5000,
                position: 'top-right',
              }}
            />
            <ReactTooltip />
          </NoSSR>
        </div>
      </Provider>
    </>
  );
}

MyApp.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object,
  err: PropTypes.object,
  reduxStore: PropTypes.object,
  treatments: PropTypes.object,
};

export default withReduxStore(MyApp);
