import * as actions from '../actions/actions';
import initialState from './initialState';

export default function authReducer(state = initialState.auth, action) {
	switch (action.type) {
	case actions.GET_USERS_REQUEST:
	case actions.UPDATE_USER_REQUEST:
	case actions.GET_ME_REQUEST:
		return { ...state };

	case actions.REGISTER_REQUEST:
		return {
			...state,
			isAuthenticated: false,
			user: action.creds,
		};

	case actions.REGISTER_SUCCESS:
		return {
			...state,
			isAuthenticated: true,
			authToken: action.authToken,
			user: action.user.user,
		};

	case actions.REGISTER_FAILURE:
		return {
			...state,
			isAuthenticated: false,
			authToken: null,
			errors: action.errors,
		};

	case actions.LOGIN_FAILURE:
		return {
			...state,
			isAuthenticated: false,
			authToken: null,
			errors: action.errors,
		};

	case actions.LOGIN_REQUEST:
		return {
			...state,
			isAuthenticated: false,
			error: null,
		};

	case actions.SHOW_LOGIN_MODAL:
		return {
			...state,
			showLoginModal: true,
			isAuthenticated: false,
		};

	case actions.HIDE_LOGIN_MODAL:
		return { ...state, showLoginModal: false };

	case actions.LOGIN_SUCCESS:
		return {
			...state,
			isAuthenticated: true,
			authToken: action.authToken,
			showLoginModal: false,
			user: action.user,
			error: null,
		};

	case actions.GET_ME_FAILURE:
		return { ...state, errors: action.errors };

	case actions.GET_ME_SUCCESS:
		return { ...state, user: action.user };

	case actions.GET_USERS_FAILURE:
		return { ...state, errors: action.errors };

	case actions.GET_USERS_SUCCESS:
		return { ...state, users: action.users };

	case actions.UPDATE_USER_SUCCESS:
		return { ...state, user: action.user };

	case actions.LOGOUT_REQUEST:
		return {
			...state,
			isAuthenticated: false,
			authToken: null,
			showLoginModal: false,
			user: {},
		};

	default:
		return state;
	}
}
