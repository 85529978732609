import * as actions from '../actions/actions';
import initialState from './initialState';

export default function httpReducer(state = initialState.http, action) {
	switch (action.type) {
	case actions.START_HTTP_REQUEST:
		return { ...state, isFetching: true };

	case actions.END_HTTP_REQUEST:
		return { ...state, isFetching: false };

	case actions.HTTP_REQUEST_FAILURE:
		return {
			...state,
			errors: action.errors,
			isFetching: false,
		};

	case actions.START_HTTP_UPLOAD_REQUEST:
		return { ...state, isUploading: true };

	case actions.END_HTTP_UPLOAD_REQUEST:
		return { ...state, isUploading: false };

	default:
		return state;
	}
}
