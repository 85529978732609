import axios from 'axios';
import Router from 'next/router';
import { toast } from 'react-hot-toast';
import cookie from 'js-cookie';
import { apiUrl } from '../utils/Config';
import * as httpActions from './httpActions';
import * as actions from './actions';
import { validateFormDataFailure } from './validatorActions';
import { getAuthCookie, getTenantCookie } from '../helpers';

axios.defaults.headers.Authorization = `Bearer ${cookie.get('elv_token')}`;

export function getApplicationsRequest() {
  return {
    type: actions.GET_APPLICATIONS_REQUEST,
  };
}

export function getApplicationsSuccess(applications) {
  return {
    type: actions.GET_APPLICATIONS_SUCCESS,
    applications,
  };
}

export function getApplicationsFailure(errors) {
  return {
    type: actions.GET_APPLICATIONS_FAILURE,
    errors,
  };
}

export function getApplicationRequest() {
  return {
    type: actions.GET_APPLICATION_REQUEST,
  };
}

export function getApplicationSuccess(application) {
  return {
    type: actions.GET_APPLICATION_SUCCESS,
    application,
  };
}

export function getApplicationFailure(errors) {
  return {
    type: actions.GET_APPLICATION_FAILURE,
    errors,
  };
}

export function createApplicationRequest() {
  return {
    type: actions.CREATE_APPLICATION_REQUEST,
  };
}

export function createApplicationSuccess(application) {
  return {
    type: actions.CREATE_APPLICATION_SUCCESS,
    application,
  };
}

export function createApplicationFailure(errors) {
  return {
    type: actions.CREATE_APPLICATION_FAILURE,
    errors,
  };
}

export function updateApplicationRequest() {
  return {
    type: actions.UPDATE_APPLICATION_REQUEST,
  };
}

export function updateApplicationSuccess(application) {
  return {
    type: actions.UPDATE_APPLICATION_SUCCESS,
    application,
  };
}

export function updateApplicationFailure(errors) {
  return {
    type: actions.UPDATE_APPLICATION_FAILURE,
    errors,
  };
}

export function createApplication(application) {
  const config = {
    method: 'post',
    url: `${apiUrl}jobposts/${application.post_id}/applications`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: application,
  };

  return function (dispatch) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getAuthCookie()}`,
    };

    dispatch(createApplicationRequest());
    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());

        if (response.data) {
          dispatch(createApplicationSuccess(response.data));
          toast.success(
            'Your application has been submitted successfully. Good luck!.'
          );

          Router.push('/dash');
        } else {
          dispatch(createApplicationFailure('Creating applications failed!'));
        }
      })
      .catch((error) => {
        dispatch(httpActions.endHttpRequest());
        dispatch(
          createApplicationFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
        console.error(error);
      });
  };
}

export function updateApplication(application, data) {
  const config = {
    method: 'post',
    url: `${apiUrl}applications/${application}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data,
  };

  return (dispatch) => {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getAuthCookie()}`,
    };

    dispatch(updateApplicationRequest());
    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());

        if (response.data) {
          dispatch(updateApplicationSuccess(response.data.data));

          return response.data;
        }
        dispatch(updateApplicationFailure('Updating applications failed!'));
      })
      .catch((error) => {
        dispatch(httpActions.endHttpRequest());
        dispatch(
          updateApplicationFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
        console.error(error);
      });
  };
}

export function getAllApplications(job, props) {
  const query = new URLSearchParams(props);
  const config = {
    method: 'get',
    url: `${apiUrl}jobposts/${job}/applications?${query.toString()}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return function (dispatch) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getAuthCookie()}`,
    };

    dispatch(getApplicationsRequest());
    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());
        if (response.data) {
          dispatch(getApplicationsSuccess(response.data));
          return response.data;
        }
        dispatch(getApplicationsFailure('Loading applications failed!'));
      })
      .catch((error) => {
        dispatch(httpActions.endHttpRequest());
        dispatch(
          getApplicationsFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
      });
  };
}

export function searchApplications(q) {
  const config = {
    method: 'get',
    url: `${apiUrl}applications/search?q=${q}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return function (dispatch) {
    dispatch(getApplicationsRequest());
    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());
        if (response.data.data) {
          dispatch(getApplicationsSuccess(response.data.data));
          return response.data.data;
        }
        dispatch(getApplicationsFailure('Loading applications failed!'));
      })
      .catch((error) => {
        dispatch(httpActions.endHttpRequest());
        dispatch(
          getApplicationsFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
      });
  };
}

export function filterApplications(filters) {
  const config = {
    method: 'post',
    url: `${apiUrl}applications/filter`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: filters,
  };

  return function (dispatch) {
    dispatch(getApplicationsRequest());
    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());
        if (response.data.data) {
          dispatch(getApplicationsSuccess(response.data.data));
          return response.data.data;
        }
        dispatch(getApplicationsFailure('Loading applications failed!'));
      })
      .catch((error) => {
        dispatch(httpActions.endHttpRequest());
        dispatch(
          getApplicationsFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
      });
  };
}

export function getMyApplications() {
  const config = {
    method: 'get',
    url: `${apiUrl}user/applications`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return function (dispatch) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getAuthCookie()}`,
    };

    dispatch(getApplicationsRequest());
    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());
        if (response.data) {
          dispatch(getApplicationsSuccess(response.data));
          return response.data;
        }
        dispatch(getApplicationsFailure('Loading applications failed!'));
      })
      .catch((error) => {
        dispatch(httpActions.endHttpRequest());
        dispatch(
          getApplicationsFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
      });
  };
}

export function getApplication(id) {
  const config = {
    method: 'get',
    url: `${apiUrl}applications/${id}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return function (dispatch) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getAuthCookie()}`,
    };

    dispatch(getApplicationRequest());
    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());
        if (response.data.data) {
          dispatch(getApplicationSuccess(response.data.data));

          return response.data.data;
        }
        dispatch(getApplicationFailure('Loading application failed!'));
      })
      .catch((error) => {
        dispatch(httpActions.endHttpRequest());
        dispatch(
          getApplicationFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
        console.error(error);
      });
  };
}

export function getApplicationRating(id, jobDescription) {
  const config = {
    method: 'post',
    url: `${apiUrl}applications/${id}/rating`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: {
      job_description: jobDescription,
    },
  };

  return function (dispatch) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getAuthCookie()}`,
    };

    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        dispatch(httpActions.endHttpRequest());
        console.error(error);
      });
  };
}

export function getOffers() {
  const config = {
    method: 'get',
    url: `${apiUrl}user/offers`,
  };

  return function (dispatch) {
    return doAxiosCall(config, dispatch);
  };
}

export function getOffer(id) {
  const config = {
    method: 'get',
    url: `${apiUrl}user/offers/${id}`,
  };

  return function (dispatch) {
    return doAxiosCall(config, dispatch);
  };
}

export function updateOffer(id, data) {
  const config = {
    url: `${apiUrl}user/offers/${id}`,
    data,
  };

  return function (dispatch) {
    return doAxiosCall(config, dispatch);
  };
}

export function getNotes(id) {
  const config = {
    method: 'get',
  };

  return function (dispatch) {
    config.url = `${apiUrl}applications/${id}/notes`;

    return doAxiosCall(config, dispatch);
  };
}

export function createNote(id, data) {
  const config = {
    data,
  };

  return function (dispatch) {
    config.url = `${apiUrl}applications/${id}/notes`;

    return doAxiosCall(config, dispatch);
  };
}

export function deleteNote(id) {
  const config = {
    method: 'delete',
  };

  return function (dispatch) {
    config.url = `${apiUrl}notes/${id}`;

    return doAxiosCall(config, dispatch);
  };
}

const doAxiosCall = (extraConfig, dispatch) => {
  const config = {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  dispatch(httpActions.startHttpRequest());

  const thecookie = getAuthCookie();
  const thetenant = getTenantCookie();

  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${thecookie}`,
    'X-Company': thetenant,
  };

  return axios({ ...config, ...extraConfig })
    .then((response) => {
      dispatch(httpActions.endHttpRequest());

      if (response.data) {
        return response.data;
      }
      dispatch(validateFormDataFailure(response.data));
    })
    .catch((error) => {
      dispatch(
        httpActions.httpRequestFailure(
          error.response &&
            (error.response.data.error ||
              error.response.data.errors ||
              error.response.data.message)
        )
      );
      console.error(error);
    });
};
