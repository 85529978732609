import axios from 'axios';
import * as actions from './actions';
import * as httpActions from './httpActions';
import { validateFormDataFailure } from './validatorActions';
import { getAuthCookie, getTenantCookie } from '../helpers';
import { apiUrl } from '../utils/Config';

export function getTasksRequest() {
  return {
    type: actions.GET_TASKS_REQUEST,
  };
}
export function getTasksSuccess(tasks) {
  return {
    type: actions.GET_TASKS_SUCCESS,
    tasks,
  };
}

export function getTasksFailure(errors) {
  return {
    type: actions.GET_TASKS_FAILURE,
    errors,
  };
}

export function getTaskRequest() {
  return {
    type: actions.GET_TASK_REQUEST,
  };
}
export function getTaskSuccess(task) {
  return {
    type: actions.GET_TASK_SUCCESS,
    task,
  };
}

export function getTaskFailure(errors) {
  return {
    type: actions.GET_TASK_FAILURE,
    errors,
  };
}

export function createTaskRequest() {
  return {
    type: actions.CREATE_TASK_REQUEST,
  };
}
export function createTaskSuccess(task) {
  return {
    type: actions.CREATE_TASK_SUCCESS,
    task,
  };
}

export function createTaskFailure(errors) {
  return {
    type: actions.CREATE_TASK_FAILURE,
    errors,
  };
}

export function updateTaskRequest() {
  return {
    type: actions.UPDATE_TASK_REQUEST,
  };
}
export function updateTaskSuccess(task) {
  return {
    type: actions.UPDATE_TASK_SUCCESS,
    task,
  };
}

export function updateTaskFailure(errors) {
  return {
    type: actions.UPDATE_TASK_FAILURE,
    errors,
  };
}

export function getTask(user, id) {
  const config = {
    method: 'get',
  };

  return function (dispatch) {
    config.url = `${apiUrl}tenant/${getTenantCookie()}/users/${
      user.uid
    }/tasks/${id}`;
    return doAxiosCall(config, dispatch);
  };
}

export function submitTask(user, id) {
  const config = {
    method: 'post',
  };

  return function (dispatch) {
    config.url = `${apiUrl}tenant/${getTenantCookie()}/users/${
      user.uid
    }/tasks/${id}/submit`;
    return doAxiosCall(config, dispatch);
  };
}

export function approveTask(user, id) {
  const config = {
    method: 'post',
  };

  return function (dispatch) {
    config.url = `${apiUrl}tenant/${getTenantCookie()}/users/${
      user.uid
    }/tasks/${id}/approve`;
    return doAxiosCall(config, dispatch);
  };
}

export function rejectTask(user, id) {
  const config = {
    method: 'post',
  };

  return function (dispatch) {
    config.url = `${apiUrl}tenant/${getTenantCookie()}/users/${
      user.uid
    }/tasks/${id}/reject`;
    return doAxiosCall(config, dispatch);
  };
}

const doAxiosCall = (extraConfig, dispatch, props = {}) => {
  const config = {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  dispatch(httpActions.startHttpRequest());
  dispatch(httpActions.startHttpRequest());

  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${getAuthCookie()}`,
    'X-Company': getTenantCookie(),
  };

  props.preRequest?.action && dispatch(props.preRequest?.action);

  return axios({ ...config, ...extraConfig })
    .then((response) => {
      dispatch(httpActions.endHttpRequest());

      if (response.data) {
        props.onSuccess?.action &&
          dispatch(props.onSuccess.action(response.data?.data));
        return response.data;
      }
      dispatch(validateFormDataFailure(response.data));
    })
    .catch((error) => {
      props.onError?.action &&
        dispatch(
          props.onError?.action(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );

      dispatch(
        httpActions.httpRequestFailure(
          error.response &&
            (error.response.data.error ||
              error.response.data.errors ||
              error.response.data.message)
        )
      );
      console.error(error);
    });
};
