const categories = [
  {
    title: 'Human Resources & Recruiting',
  },
  {
    title: 'Management & Leadership',
    description:
      'The use of organizational, managerial, and leadership skills to accomplish organizational goals.',
  },
  {
    title: 'Software & IT',
  },
  {
    title: 'Telecommunications',
  },
  {
    title: 'Marketing & Sales',
  },
  {
    title: 'Customer Service',
  },
  {
    title: 'Consultancy',
  },
  {
    title: 'Entertainment',
  },
  {
    title: 'Design & Creative',
  },
  {
    title: 'Finance & Investment',
  },
  {
    title: 'Accounting',
  },
  {
    title: 'Data & Analytics',
    description:
      'Logical processing of information and data to produce useable results.',
  },
  {
    title: 'Construction',
  },
  {
    title: 'Engineering',
  },
  {
    title: 'Manufacturing',
  },
  {
    title: 'Media & Communications',
  },
  {
    title: 'Education & Training',
  },
  {
    title: 'Security',
  },
  {
    title: 'Research',
  },
  {
    title: 'Project Management',
  },
  {
    title: 'Property Management',
  },
  {
    title: 'Operations & Administration',
  },
  {
    title: 'Tourism & Hotel Management',
  },
  {
    title: 'Product Supply Chain, Logistics',
  },
  {
    title: 'Automotive',
  },
  {
    title: 'Aviation',
  },
  {
    title: 'Healthcare',
  },
  {
    title: 'Legal',
  },
  {
    title: 'Hospitality Food & beverage',
  },
  {
    title: 'Fashion',
  },
  {
    title: 'Environment & Energy',
  },
  {
    title: 'Agriculture, Fishing & Forestry',
  },
  {
    title: 'Insurance',
  },
  {
    title: 'Business Development',
  },
  {
    title: 'Other',
  },
];

const processCategory = (cat, separator = '_') =>
  cat &&
  cat
    .replace(/\s&\s/g, separator)
    .replace(/&/g, separator)
    .replace(/,\s/g, separator)
    .replace(/\s/g, separator)
    .toLowerCase();

const getCategoryName = (cat) => {
  const theCategory = categories.find(
    (ct) => ct && processCategory(ct.title) === cat
  );

  return theCategory.title;
};

module.exports = {
  categories,
  processCategory,
  getCategoryName,
};
