import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'next/link';
import Loader from '../loader';
import { trackEvent } from '../../../helpers';

function Button(props) {
  const {
    id,
    fullWidth,
    justify,
    type,
    disabled,
    design,
    afterIcon,
    beforeIcon,
    href,
    target,
    download,
    onClick,
    className,
    isLoading,
    scroll = true,
    prefetch = true,
    asDefaultATag = false,
    tracked,
    trackData,
    itemProp,
    toolTip = null,
  } = props;

  const btnOnClick = (event) => {
    if (tracked) {
      trackEvent(trackData);
    }

    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  const primaryBtnClasses = classNames(
    'btn relative inline-flex flex-nowrap whitespace-nowrap text-center items-center text-sm leading-none relative focus:outline-none cursor-pointer group',
    {
      'font-medium py-3 px-4 rounded':
        !design || design === 'primary' || design === 'secondary',
    },
    {
      'font-medium border py-3 px-4 rounded': design && design === 'outlined',
    },
    {
      'font-medium py-3 px-4 rounded-none block':
        design && (design === 'link' || design === 'plain'),
    },
    {
      'bg-blue hover:bg-blue-darker text-white hover:text-white':
        (!design || design === 'primary') && !disabled,
    },
    {
      'bg-white border border-gray-300 hover:border-gray-800 shadow-sm hover:bg-gray-800 text-gray-600 hover:text-white':
        design === 'secondary' && !disabled,
    },
    {
      'hover:bg-blue-dark hover:text-white text-gray-700 border-gray-700 hover:border-blue-900':
        design === 'outlined' && !disabled && design !== 'plain',
    },
    {
      'hover:text-blue-dark':
        (design === 'link' || design === 'plain') && !disabled,
    },
    {
      'bg-gray-200 hover:bg-gray-300 text-gray-700 cursor-not-allowed':
        disabled &&
        (!design ||
          design === 'primary' ||
          design === 'outlined' ||
          design === 'plain' ||
          design === 'secondary'),
    },
    {
      'hover:text-gray-900 text-gray-600 cursor-not-allowed':
        disabled && (design === 'link' || design === 'plain'),
    },
    { 'w-full': fullWidth },
    { 'justify-center': !justify },
    { 'justify-start': justify === 'start' },
    { 'justify-between': justify === 'between' },
    { 'justify-end': justify === 'end' },
    className
  );

  if (design === 'link' || href) {
    if (asDefaultATag) {
      return (
        <a
          className={primaryBtnClasses}
          target={target}
          download={download}
          onClick={btnOnClick}
          id={id}
          itemProp={itemProp}
          href={href || ''}
        >
          {beforeIcon}
          {props.children}
          {afterIcon}
        </a>
      );
    }
    return (
      (<Link
        href={href || ''}
        scroll={scroll}
        prefetch={prefetch}
        passHref
        className={primaryBtnClasses}
        target={target}
        download={download}
        onClick={btnOnClick}
        id={id}
        itemProp={itemProp}>

        {beforeIcon}
        {props.children}
        {afterIcon}

      </Link>)
    );
  }

  return (
    <button
      className={primaryBtnClasses}
      type={type || 'button'}
      disabled={disabled}
      onClick={btnOnClick}
      id={id}
    >
      {beforeIcon}
      {props.children}
      {afterIcon}
      {toolTip && (
        <span className="absolute min-w-[60px] -top-4 leading-none transition-all p-[2px] text-[10px] bg-gray-500 rounded-sm hidden justify-center group-hover:flex after:w-2 after:h-2 after:bg-gray-500 after:absolute after:-bottom-1 after:rotate-45 after:z-0">
          <span className="text-[10px] leading-none z-10 text-white">
            {toolTip}
          </span>
        </span>
      )}
      {isLoading && (
        <div className="absolute w-full h-full bg-blue-50 bg-opacity-50 flex justify-center items-center">
          <Loader size="xs" />
        </div>
      )}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  design: PropTypes.string,
  beforeIcon: PropTypes.any,
  afterIcon: PropTypes.any,
  href: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  justify: PropTypes.string,
  download: PropTypes.string,
  tracked: PropTypes.bool,
  trackData: PropTypes.object,
  id: PropTypes.string,
  scroll: PropTypes.bool,
  prefetch: PropTypes.bool,
  itemProp: PropTypes.string,
  asDefaultATag: PropTypes.bool,
  toolTip: PropTypes.string,
};

export default Button;
