import { toast } from 'react-hot-toast';

const notifyTypes = ['UPDATE_JOBPOST_SUCCESS'];

const notificationHandler = (store) => (next) => (action) => {
	if (!action) return;

	if (shouldNotify(action?.type) && parseMessage(action.type) !== '') {
		toast.success(parseMessage(action.type));
	}
	handleMessage(store, action.errors);

	return next(action);
};

function parseMessage(message) {
	switch (message) {
	case 'UPDATE_JOBPOST_SUCCESS':
		return 'Job updated successfully! Pending review & approval...';

	default:
		return '';
	}
}

function handleMessage(store, error) {
	switch (error) {
	case 'Unauthenticated.':
		break;

	default:
		break;
	}
}

function shouldNotify(type) {
	return notifyTypes.includes(type);
}

export { notificationHandler };
