export const apiUrl = process.env.REACT_APP_API_URL;

export const env = (value) => {
	if (!value) {
		throw Error('Env value not specified.');
	}

	return process.env[value];
};

export const splits = ['hr_alpha'];
