import * as actions from '../actions/actions';
import initialState from './initialState';

export default function validatorReducer(
  state = initialState.validator,
  action
) {
  switch (action.type) {
    case actions.VALIDATE_FORM_DATA_REQUEST:
      return { isValid: false, errors: {} };

    case actions.VALIDATE_FORM_DATA_SUCCESS:
      return { isValid: true, errors: {} };

    case actions.VALIDATE_FORM_DATA_FAILURE:
      return { isValid: false, errors: action.errors || {} };

    default:
      return state;
  }
}
