import * as actions from './actions';

export function getUpdatesRequest() {
	return {
		type: actions.GET_UPDATES_REQUEST,
	};
}
export function getUpdatesSuccess(updates) {
	return {
		type: actions.GET_UPDATES_SUCCESS,
		updates,
	};
}

export function getUpdatesFailure(errors) {
	return {
		type: actions.GET_UPDATES_FAILURE,
		errors,
	};
}

export function getUpdateRequest() {
	return {
		type: actions.GET_UPDATE_REQUEST,
	};
}
export function getUpdateSuccess(update) {
	return {
		type: actions.GET_UPDATE_SUCCESS,
		update,
	};
}

export function getUpdateFailure(errors) {
	return {
		type: actions.GET_UPDATE_FAILURE,
		errors,
	};
}

export function createUpdateRequest() {
	return {
		type: actions.CREATE_UPDATE_REQUEST,
	};
}
export function createUpdateSuccess(update) {
	return {
		type: actions.CREATE_UPDATE_SUCCESS,
		update,
	};
}

export function createUpdateFailure(errors) {
	return {
		type: actions.CREATE_UPDATE_FAILURE,
		errors,
	};
}

export function updateUpdateRequest() {
	return {
		type: actions.UPDATE_UPDATE_REQUEST,
	};
}
export function updateUpdateSuccess(update) {
	return {
		type: actions.UPDATE_UPDATE_SUCCESS,
		update,
	};
}

export function updateUpdateFailure(errors) {
	return {
		type: actions.UPDATE_UPDATE_FAILURE,
		errors,
	};
}
