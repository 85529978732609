export const parseUnderScore = (text) => {
  if (!text) return '';

  return text && text.length > 0 && text.replace(/_/g, ' ');
};

export const parseDash = (text) =>
  text && text.length > 0 && text.replace(/-/g, ' ');

export const excerpt = (text, length) =>
  text && text.substring(0, length) + (text.length > length ? '...' : '');

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  const wordsArray = s.split(' ');
  const tempWordsArray = [];

  wordsArray.forEach((w) => {
    tempWordsArray.push(w.charAt(0).toUpperCase() + w.slice(1));
  });

  return tempWordsArray.join(' ');
};

export const parseDisplayText = (text) =>
  capitalize(parseUnderScore(parseDash(text)));

export const parseValueForUnknown = (value) => {
  if (!value) return 'Unknown';

  return value;
};

export const snakeCase = (string) =>
  string
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_');
