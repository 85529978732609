import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';

// eslint-disable-next-line no-unused-vars
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import { notificationHandler } from '../middlewares/notificationHandler';
import { errorHandler } from '../middlewares/errorHandler';
import rootReducer from '../reducers';

function configureStore(initialState) {
  let middlewares = [
    thunk,
    errorHandler,
    notificationHandler,
    // reduxImmutableStateInvariant(),
  ];

  if (process.env.NODE_ENV === 'development') {
    const { logger } = require('redux-logger');

    middlewares = [...middlewares, logger];
  }

  const persistConfig = {
    key: 'primary',
    storage,
    whitelist: ['jobposts', 'auth', 'updates', 'tasks', 'applications'],
    stateReconciler: autoMergeLevel1,
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  return createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares))
  );
}

export { configureStore };
