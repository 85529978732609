import isEmpty from 'lodash.isempty';

/**
 * Check if user has a complete profile
 *
 * @param {object} user
 * @return {boolean}
 */
export const hasCompletedProfile = (user) =>
  !isEmpty(user) &&
  !isEmpty(user.bio) &&
  !isEmpty(user.phone) &&
  !isEmpty(user.dob);

/**
 * Check if user can edit a job post
 *
 * @param {object} post
 * @param {object} user
 * @return {boolean}
 */
export const userCanEditPost = (post, user) =>
  userCan(user, 'manage-jobpost') ||
  (post.owner && post.owner.uid === user.uid);

/**
 * Check if user has verified email
 *
 * @param {object} user
 * @return {boolean}
 */
export const userHasVerifiedEmail = (user) =>
  user && !isEmpty(user.email_verified_at);

/**
 * Parse user's role
 *
 * @param {object} tenant
 * @param {string} role
 * @return {string}
 */
export const parseUserRole = (tenant, role) =>
  role.replace(`${tenant.name}.`, '');

/**
 * Check if user is admin
 *
 * @param {object} user
 * @return {boolean}
 */
export const userIsAdmin = (user) =>
  user &&
  user.roles &&
  user.tenant &&
  user.roles.find((r) => r.name === 'admin');

/**
 * Check if user can | has permission
 *
 * @param {object} user
 * @return {boolean}
 */
export const userCan = (user, action) => {
  if (userIsAdmin(user)) {
    return true;
  }

  return (
    user &&
    user.roles &&
    user.tenant &&
    user.permissions &&
    user.permissions.find((p) => p.name === action)
  );
};

/**
 * Get user's dashboard redirect path
 *
 * @param {object} user
 * @param {string} [destination='dash']
 * @return {string} dashboard path dash|hr/tenant_name
 */
export const getUserRedirectPath = (user, destination = 'dash') => {
  if (destination === 'dash') {
    if (user.tenant && user.tenant.name) {
      return `/hr/${user.tenant.name}`;
    }

    return '/account';
  }
};

export const hasSubscription = (user, name) =>
  user &&
  user.tenant &&
  user.tenant.subscriptions &&
  user.tenant.subscriptions.find((sup) => sup.name == name);

export const getUsersName = (user) => {
  if (!user) return '';

  if (user.display_name) {
    return user.display_name;
  }

  return user.name;
};

/**
 * @todo add logic
 */
// eslint-disable-next-line no-unused-vars
export const subHasModule = (user, sub, module) => true;
