import axios from 'axios';
import cookie from 'js-cookie';
import * as actions from './actions';
import * as httpActions from './httpActions';
import { apiUrl } from '../utils/Config';
import { getAuthCookie, getTenantCookie, httpAgent } from '../helpers';

axios.defaults.headers.Authorization = `Bearer ${cookie.get('elv_token')}`;

export function getJobpostsRequest() {
  return {
    type: actions.GET_JOBPOSTS_REQUEST,
  };
}

export function getJobpostsSuccess(jobposts) {
  return {
    type: actions.GET_JOBPOSTS_SUCCESS,
    jobposts,
  };
}

export function getJobpostsFailure(errors) {
  return {
    type: actions.GET_JOBPOSTS_FAILURE,
    errors,
  };
}

export function getRelatedJobpostsRequest() {
  return {
    type: actions.GET_RELATED_JOBPOSTS_REQUEST,
  };
}

export function getRelatedJobpostsSuccess(jobposts) {
  return {
    type: actions.GET_RELATED_JOBPOSTS_SUCCESS,
    jobposts,
  };
}

export function getRelatedJobpostsFailure(errors) {
  return {
    type: actions.GET_RELATED_JOBPOSTS_FAILURE,
    errors,
  };
}

export function getJobpostRequest() {
  return {
    type: actions.GET_JOBPOST_REQUEST,
  };
}

export function getJobpostSuccess(jobpost) {
  return {
    type: actions.GET_JOBPOST_SUCCESS,
    jobpost,
  };
}

export function getJobpostFailure(errors) {
  return {
    type: actions.GET_JOBPOST_FAILURE,
    errors,
  };
}

export function createJobpostRequest() {
  return {
    type: actions.CREATE_JOBPOST_REQUEST,
  };
}

export function createJobpostSuccess(jobpost) {
  return {
    type: actions.CREATE_JOBPOST_SUCCESS,
    jobpost,
  };
}

export function createJobpostFailure(errors) {
  return {
    type: actions.CREATE_JOBPOST_FAILURE,
    errors,
  };
}

export function updateJobpostRequest() {
  return {
    type: actions.UPDATE_JOBPOST_REQUEST,
  };
}

export function updateJobpostSuccess(jobpost) {
  return {
    type: actions.UPDATE_JOBPOST_SUCCESS,
    jobpost,
  };
}

export function updateJobpostFailure(errors) {
  return {
    type: actions.UPDATE_JOBPOST_FAILURE,
    errors,
  };
}

export function createJobpost(jobpost) {
  const config = {
    method: 'post',
    url: `${apiUrl}jobposts/`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: jobpost,
  };

  return function (dispatch) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getAuthCookie()}`,
      'X-Company': getTenantCookie(),
    };

    dispatch(createJobpostRequest());
    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());

        if (response.data) {
          dispatch(createJobpostSuccess(response.data));

          return response.data;
        }
        dispatch(createJobpostFailure('Creating jobposts failed!'));
      })
      .catch((error) => {
        dispatch(
          createJobpostFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
        console.error(error);
      });
  };
}

export function updateJobpost(jobpost) {
  const config = {
    method: 'post',
    url: `${apiUrl}jobposts/${jobpost.jid}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: jobpost,
  };

  return function (dispatch) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getAuthCookie()}`,
      'X-Company': getTenantCookie(),
    };

    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());

        if (response.data) {
          dispatch(updateJobpostSuccess(response.data.data));
          return response.data;
        }
        dispatch(updateJobpostFailure('Updating jobpost failed!'));
      })
      .catch((error) => {
        dispatch(
          httpActions.httpRequestFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
        console.error(error);
      });
  };
}

export function getAllJobposts(page) {
  const config = {
    method: 'get',
    url: `${apiUrl}jobposts${page ? `?page=${page}` : ''}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return function (dispatch) {
    dispatch(getJobpostsRequest());
    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());
        if (response.data) {
          dispatch(getJobpostsSuccess(response.data));
          return response.data;
        }
        dispatch(getJobpostsFailure('Loading jobposts failed!'));
      })
      .catch((error) => {
        dispatch(
          getJobpostsFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
      });
  };
}

export function searchJobposts(q) {
  const config = {
    method: 'get',
    url: `${apiUrl}jobposts/search?q=${q}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return function (dispatch) {
    dispatch(getJobpostsRequest());
    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());
        if (response.data) {
          dispatch(getJobpostsSuccess(response.data));
          return response.data;
        }
        dispatch(getJobpostsFailure('Loading jobposts failed!'));
      })
      .catch((error) => {
        dispatch(
          getJobpostsFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
      });
  };
}

export function filterJobposts(filters) {
  const config = {
    method: 'post',
    url: `${apiUrl}jobposts/filter`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: filters,
  };

  if (process.env.NODE_ENV === 'development') {
    config.httpsAgent = httpAgent();
  }

  return function (dispatch) {
    dispatch(httpActions.startHttpRequest());

    dispatch(getJobpostsRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());
        if (response.data) {
          dispatch(getJobpostsSuccess(response.data));
          return response.data;
        }
        dispatch(getJobpostsFailure('Loading jobposts failed!'));
      })
      .catch((error) => {
        dispatch(
          getJobpostsFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
      });
  };
}

export function adminFilterJobposts(filters) {
  const config = {
    method: 'post',
    url: `${apiUrl}admin/jobposts/filter`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: filters,
  };

  if (process.env.NODE_ENV === 'development') {
    config.httpsAgent = httpAgent();
  }

  return function (dispatch) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getAuthCookie()}`,
    };
    dispatch(getJobpostsRequest());
    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());
        if (response.data) {
          // dispatch(getJobpostsSuccess(response.data));
          return response.data;
        }
        dispatch(getJobpostsFailure('Loading jobposts failed!'));
      })
      .catch((error) => {
        dispatch(
          getJobpostsFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
      });
  };
}

export function getMyJobposts() {
  const config = {
    method: 'get',
    url: `${apiUrl}user/jobposts`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return function (dispatch) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getAuthCookie()}`,
      'X-Company': getTenantCookie(),
    };

    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());
        if (response.data) {
          dispatch(getJobpostsSuccess(response.data));
          return response.data;
        }
        dispatch(getJobpostsFailure('Loading jobposts failed!'));
      })
      .catch((error) => {
        dispatch(httpActions.endHttpRequest());
        dispatch(
          getJobpostsFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
      });
  };
}

export function getUserJobposts(id) {
  const config = {
    method: 'get',
    url: `${apiUrl}board/${id}/jobposts`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (process.env.NODE_ENV === 'development') {
    config.httpsAgent = httpAgent();
  }

  return function (dispatch) {
    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());
        return response.data;
      })
      .catch((error) => {
        dispatch(
          getJobpostsFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
      });
  };
}

export function getJobpost(id, slug = true) {
  const config = {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (process.env.NODE_ENV === 'development') {
    config.httpsAgent = httpAgent();
  }

  return function (dispatch) {
    const tenant = getTenantCookie();

    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getAuthCookie()}`,
      'X-Company': tenant,
    };

    config.url = `${apiUrl}jobposts/${id}${slug ? '' : '/jid'}`;

    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());
        if (response.data.data) {
          dispatch(getJobpostSuccess(response.data.data));

          return response.data.data;
        }
        dispatch(getJobpostFailure('Loading jobpost failed!'));
      })
      .catch((error) => {
        dispatch(
          httpActions.httpRequestFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
        console.error(error);
      });
  };
}

export function getTenantJobpost(id) {
  const config = {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (process.env.NODE_ENV === 'development') {
    config.httpsAgent = httpAgent();
  }

  return function (dispatch) {
    const tenant = getTenantCookie();

    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getAuthCookie()}`,
      'X-Company': tenant,
    };

    config.url = `${apiUrl}tenant/${tenant}/jobposts/${id}`;

    dispatch(getJobpostRequest());
    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());
        if (response.data.data) {
          dispatch(getJobpostSuccess(response.data.data));

          return response.data.data;
        }
        dispatch(getJobpostFailure('Loading jobpost failed!'));
      })
      .catch((error) => {
        dispatch(
          httpActions.httpRequestFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
        console.error(error);
      });
  };
}

export function deleteJobpost(id) {
  const config = {
    method: 'delete',
    url: `${apiUrl}jobposts/${id}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (process.env.NODE_ENV === 'development') {
    config.httpsAgent = httpAgent();
  }

  return function (dispatch) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getAuthCookie()}`,
      'X-Company': getTenantCookie(),
    };

    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());
        return response;
      })
      .catch((error) => {
        dispatch(
          httpActions.httpRequestFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
        console.error(error);
      });
  };
}

export function flagJobpost(jid) {
  const config = {
    method: 'POST',
    url: `${apiUrl}flag/${jid}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (process.env.NODE_ENV === 'development') {
    config.httpsAgent = httpAgent();
  }

  return function (dispatch) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getAuthCookie()}`,
    };

    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());
        return response;
      })
      .catch((error) => {
        dispatch(
          httpActions.httpRequestFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
        console.error(error);
      });
  };
}

export function closeJobpost(id) {
  const config = {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (process.env.NODE_ENV === 'development') {
    config.httpsAgent = httpAgent();
  }

  const tenant = getTenantCookie();

  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${getAuthCookie()}`,
    'X-Company': tenant,
  };

  config.url = `${apiUrl}tenant/${tenant}/jobposts/${id}/close`;

  return function (dispatch) {
    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());
        if (response.data.data) {
          dispatch(getJobpostSuccess(response.data.data));

          return response.data.data;
        }
      })
      .catch((error) => {
        dispatch(
          httpActions.httpRequestFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
        console.error(error);
      });
  };
}

export function getRelatedJobposts(id) {
  const config = {
    method: 'get',
    url: `${apiUrl}jobposts/${id}/related`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (process.env.NODE_ENV === 'development') {
    config.httpsAgent = httpAgent();
  }

  return function (dispatch) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getAuthCookie()}`,
    };

    dispatch(getRelatedJobpostsRequest());
    dispatch(httpActions.startHttpRequest());

    return axios(config)
      .then((response) => {
        dispatch(httpActions.endHttpRequest());
        if (response.data.data) {
          dispatch(getRelatedJobpostsSuccess(response.data.data));

          return response.data.data;
        }
        dispatch(getRelatedJobpostsFailure('Loading jobposts failed!'));
      })
      .catch((error) => {
        dispatch(
          getRelatedJobpostsFailure(
            error.response &&
              (error.response.data.error ||
                error.response.data.errors ||
                error.response.data.message)
          )
        );
        console.error(error);
      });
  };
}
