import * as actions from '../actions/actions';
import initialState from './initialState';

export default function jobpostReducer(state = initialState.jobposts, action) {
	switch (action.type) {
	case actions.GET_JOBPOSTS_SUCCESS:
		return { ...state, jobposts: action.jobposts };

	case actions.GET_JOBPOSTS_REQUEST:
		return {
			...state,
			jobposts: [],
			jobpost: {},
			related: [],
		};

	case actions.GET_JOBPOSTS_FAILURE:
		return {
			...state,
			jobposts: [],
			errors: action.errors,
		};

	case actions.GET_RELATED_JOBPOSTS_SUCCESS:
		return { ...state, related: action.jobposts };

	case actions.GET_RELATED_JOBPOSTS_REQUEST:
		return { ...state, related: [] };

	case actions.GET_RELATED_JOBPOSTS_FAILURE:
		return {
			...state,
			related: [],
			errors: action.errors,
		};

	case actions.GET_JOBPOST_SUCCESS:
		return { ...state, jobpost: action.jobpost };
	case actions.GET_JOBPOST_REQUEST:
		return {
			...state,
			jobpost: {},
			errors: {},
		};
	case actions.GET_JOBPOST_FAILURE:
		return {
			...state,
			jobpost: {},
			errors: action.errors,
		};
	case actions.CREATE_JOBPOST_SUCCESS:
		return { ...state, jobpost: action.jobpost };

	case actions.CREATE_JOBPOST_REQUEST:
		return { ...state };

	case actions.CREATE_JOBPOST_FAILURE:
		return {
			...state,
			jobpost: {},
			errors: action.errors,
		};
	case actions.UPDATE_JOBPOST_SUCCESS:
		return { ...state, jobpost: action.jobpost };

	case actions.UPDATE_JOBPOST_REQUEST:
		return { ...state, jobpost: {} };

	case actions.UPDATE_JOBPOST_FAILURE:
		return {
			...state,
			jobpost: {},
			errors: action.errors,
		};

	default:
		return state;
	}
}
