import * as actions from '../actions/actions';
import initialState from './initialState';

export default function Reducer(state = initialState.storage, action) {
	switch (action.type) {
	case actions.UPLOAD_FILE_SUCCESS:
		return { ...state, file: action.file };

	case actions.UPLOAD_FILE_REQUEST:
		return { ...state, file: action.file };

	case actions.UPLOAD_FILE_FAILURE:
		return { ...state, errors: action.errors };
	default:
		return state;
	}
}
