import { isEmpty } from 'lodash';
import * as actions from './actions';
import { capitalize, isNumeric, parseUnderScore } from '../helpers';

export function validateFormDataRequest() {
  return {
    type: actions.VALIDATE_FORM_DATA_REQUEST,
  };
}

export function validateFormDataSuccess() {
  return {
    type: actions.VALIDATE_FORM_DATA_SUCCESS,
  };
}

export function validateFormDataFailure(errors) {
  return {
    type: actions.VALIDATE_FORM_DATA_FAILURE,
    errors,
  };
}

export function validateData(formData, rules, final = false) {
  return (dispatch) => {
    dispatch(validateFormDataRequest());

    let errors = {};

    if (!rules) {
      console.error('Validation rules not defined.');
    } else {
      for (const input in rules) {
        if (
          rules[input].required &&
          formData[input] !== 0 &&
          !formData[input]
        ) {
          errors[input] = `${parseUnderScore(capitalize(input))} is required!`;
        }
        if (rules[input].type && !isEmpty(formData[input])) {
          if (rules[input].type === 'number' && !isNumeric(formData[input])) {
            errors[input] = `${parseUnderScore(
              capitalize(input)
            )} should a valid number!`;
          }
        }
      }
    }

    if (Object.keys(errors).length !== 0) {
      errors = final ? errors : {};

      dispatch(validateFormDataFailure(errors));
    } else {
      dispatch(validateFormDataSuccess());
    }
  };
}
