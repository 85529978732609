import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const useMounted = () => {
	const [mounted, setMounted] = useState(false);
	useEffect(() => setMounted(true), []);
	return mounted;
};

function NoSSR({ children }) {
	const isMounted = useMounted();
	return <>{isMounted && children}</>;
}

NoSSR.propTypes = {
	children: PropTypes.object,
};

export default NoSSR;
