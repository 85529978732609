import React, { useEffect } from 'react';
import Router from 'next/router';
import nextCookie from 'next-cookies';
import cookie from 'js-cookie';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import isEmpty from 'lodash.isempty';
import { splits } from './Config';
import { hasCompletedProfile } from '../helpers/user';
import { parseRedirectUrl } from '../helpers';

export const login = ({
  user,
  goto,
  redirect = true,
  message = 'Logged in successfully!',
}) => {
  cookie.set('elv_token', user.token, {
    expires: 1,
    sameSite: 'strict',
    domain: '.elevolt.co.ke',
  });
  cookie.set('elv_tenant', user.user.tenant_id, {
    expires: 1,
    sameSite: 'strict',
    domain: '.elevolt.co.ke',
  });

  cookie.set('elv_user', user.user.uid, { expires: 1, sameSite: 'strict' });

  if (user.user.tfa_enabled && user.user.tfa_status !== 1) {
    Router.push('/auth/security-check');
    return;
  }

  toast.success(message);

  if (!isEmpty(goto) && redirect) {
    Router.push(goto.startsWith('/') ? goto : `/${goto}`);
    return;
  }

  if (redirect) {
    if (hasCompletedProfile(user)) {
      Router.push('/dash');
    } else {
      Router.push('/account');
    }
  }
};

export const auth = (ctx) => {
  const { elv_token, elv_user, elv_tenant } = nextCookie(ctx);

  return { elv_token, elv_user, elv_tenant };
};

export const logout = () => {
  cookie.remove('elv_token', { domain: '.elevolt.co.ke' });
  cookie.remove('elv_tenant', { domain: '.elevolt.co.ke' });
  cookie.remove('elv_user', { domain: '.elevolt.co.ke' });

  if (typeof window !== 'undefined') {
    window.localStorage.removeItem('persist:primary');
    window.localStorage.removeItem('elv_user');
    window.localStorage.setItem('logout', Date.now());

    if (window.location.pathname !== '/login') {
      Router.push('/login');
    }
  }
};

export const withAuthSync = (WrappedComponent) => {
  function Wrapper(props) {
    const user = useSelector((state) => state.auth.user);

    const syncLogout = (event) => {
      if (event.key === 'logout') {
        Router.push('/login');
      }
    };

    useEffect(() => {
      if (user && user.tfa_enabled && user.tfa_status === 0) {
        Router.push('/auth/security-check');
      }
    }, [user]);

    useEffect(() => {
      if (user?.tfa_enabled && user?.tfa_status === 0) {
        Router.push('/auth/security-check');
        return;
      }

      window.addEventListener('storage', syncLogout);

      return () => {
        window.removeEventListener('storage', syncLogout);
        window.localStorage.removeItem('logout');
      };
    }, []);

    const newProps = { ...props, user };

    return <WrappedComponent {...newProps} />;
  }

  Wrapper.getInitialProps = async (ctx) => {
    const { elv_token: token } = auth(ctx);

    if (!token && ctx.req) {
      const returnTo = ctx.req.url ? parseRedirectUrl(ctx.req.url) : '';
      ctx.res.writeHead(301, {
        Location: `/login?returnTo=${returnTo}`,
      });
      ctx.res.end();
      return;
    }

    let componentProps = {};

    if (typeof WrappedComponent.getInitialProps === 'function') {
      componentProps = await WrappedComponent.getInitialProps(ctx);
    }

    return { ...componentProps, token };
  };

  return Wrapper;
};

export const withSplitIO = (WrappedComponent) =>
  (class AppWithSplitio extends React.Component {
    constructor(props) {
      super(props);
    }

    static async getInitialProps(appContext) {
      let appProps = {};
      let treatments = {};
      let componentProps = {};

      if (typeof WrappedComponent.getInitialProps === 'function') {
        appProps = await WrappedComponent.getInitialProps(appContext);
      }

      if (
        appContext.Component &&
        typeof appContext.Component.getInitialProps === 'function'
      ) {
        componentProps = await appContext.Component.getInitialProps(
          appContext.ctx
        );
      }

      if (appContext.ctx && appContext.ctx.req) {
        const { elv_user: userkey } = auth(appContext.ctx);
        const userKey = userkey || 'anonymous';
        const spClient = {};
        // const spClient = require('../lib/splitio').default;

        if (spClient) {
          await spClient.ready();
          treatments = await spClient.getTreatments(userKey, [...splits]);
        }
      }

      return {
        ...appProps,
        pageProps: componentProps,
        treatments,
      };
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  });
