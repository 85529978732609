import isEmpty from 'lodash.isempty';
import { toast } from 'react-hot-toast';
import { showLoginModal } from '../actions/authActions';

const notifyTypes = [
  'REGISTER_FAILURE',
  'LOGIN_FAILURE',
  'FORGOT_PASSWORD_FAILURE',
  'UPDATE_PASSWORD_FAILURE',
  'GET_ME_FAILURE',
  'LOGOUT_FAILURE',
  'HTTP_REQUEST_FAILURE',
  'GET_JOBPOSTS_FAILURE',
  'UPLOAD_FILE_FAILURE',
  'CREATE_APPLICATION_FAILURE',
  'CREATE_JOBPOST_FAILURE',
  'UPDATE_JOBPOST_FAILURE',
];

const errorHandler = (store) => (next) => (action) => {
  if (!action) return;

  if (
    action?.errors &&
    typeof action.errors === 'object' &&
    Object.keys(action.errors).length > 0
  ) {
    for (const error in action.errors) {
      if (shouldNotify(action.type)) {
        const msg = parseErrorMessage(action.errors[error]);
        if (msg) {
          toast.error(msg);
        }
      }
      handleError(store, action.errors);
    }
  }

  if (typeof action?.errors === 'string') {
    if (action.errors === 'Unauthenticated.') {
      const state = store.getState();
      if (state.auth?.user?.tfa_enabled && state.auth?.user?.tfa_status === 0) {
        window.location.pathname = '/auth/security-check';
        return;
      }
    }

    if (shouldNotify(action.type)) {
      const msg = parseErrorMessage(action.errors);
      if (msg) {
        toast.error(msg);
      }
    }
    handleError(store, action.errors);
  }

  return next(action);
};

function parseErrorMessage(message) {
  if (isEmpty(message)) {
    return 'An error occured.';
  }

  const msg = typeof message === 'object' ? message[0] : message;

  switch (msg) {
    case 'Unauthenticated.':
      return null;
    // return 'Unauthenticated! Please login to continue.';
    case 'Invalid signature':
      return 'An error occured proccessing your request.';

    default:
      return msg;
  }
}

function handleError(store, error) {
  switch (error) {
    case 'Unauthenticated.':
      store.dispatch(showLoginModal());
      break;

    default:
      break;
  }
}

function shouldNotify(type) {
  return notifyTypes.includes(type);
}

export { errorHandler };
