import { useMemo } from 'react';
import { configureStore } from '../store/configureStore';
// import { initSplitSdk } from '@splitsoftware/splitio-redux';

let store;

// const sdkBrowserConfig = {
// 	core: {
// 		authorizationKey: 'c21t1rjmr7u9scs18ge7h8pqto19oqnme4so',
// 		key: 'user',
// 	},
// };

export const initializeStore = (preloadedState) => {
	let _store = store ?? configureStore(preloadedState);

	// After navigating to a page with an initial Redux state, merge that state
	// with the current state in the store, and create a new store
	if (preloadedState && store) {
		_store = configureStore({
			...store.getState(),
			...preloadedState,
		});
		// Reset the current store
		store = undefined;
	}

	// For SSG and SSR always create a new store
	if (typeof window === 'undefined') return _store;
	// Create the store once in the client
	if (!store) store = _store;

	// Splitio
	// _store.dispatch(initSplitSdk({ config: sdkBrowserConfig }));

	return _store;
};

export function useStore(initialState) {
	const store = useMemo(() => initializeStore(initialState), [initialState]);

	return store;
}
