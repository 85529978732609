import * as actions from '../actions/actions';
import initialState from './initialState';

export default function updateReducer(state = initialState.updates, action) {
	switch (action.type) {
	case actions.GET_UPDATES_SUCCESS:
		return { ...state, updates: action.updates };

	case actions.GET_UPDATES_REQUEST:
		return { ...state };

	case actions.GET_UPDATES_FAILURE:
		return {
			...state,
			updates: [],
			errors: action.errors,
		};

	case actions.GET_UPDATE_SUCCESS:
		return { ...state, update: action.update };

	case actions.GET_UPDATE_REQUEST:
		return {
			...state,
			update: {},
			errors: {},
		};
	case actions.GET_UPDATE_FAILURE:
		return {
			...state,
			update: {},
			errors: action.errors,
		};
	case actions.CREATE_UPDATE_SUCCESS:
		return { ...state, update: action.update };

	case actions.CREATE_UPDATE_REQUEST:
		return { ...state };

	case actions.CREATE_UPDATE_FAILURE:
		return {
			...state,
			update: {},
			errors: action.errors,
		};
	case actions.UPDATE_UPDATE_SUCCESS:
		return { ...state, update: action.update };

	case actions.UPDATE_UPDATE_REQUEST:
		return { ...state };

	case actions.UPDATE_UPDATE_FAILURE:
		return {
			...state,
			update: {},
			errors: action.errors,
		};

	default:
		return state;
	}
}
