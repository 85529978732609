import * as actions from '../actions/actions';
import initialState from './initialState';

export default function taskReducer(state = initialState.tasks, action) {
	switch (action.type) {
	case actions.GET_TASKS_SUCCESS:
		return { ...state, tasks: action.tasks };

	case actions.GET_TASKS_REQUEST:
		return { ...state };

	case actions.GET_TASKS_FAILURE:
		return {
			...state,
			tasks: [],
			errors: action.errors,
		};

	case actions.GET_TASK_SUCCESS:
		return { ...state, task: action.task };

	case actions.GET_TASK_REQUEST:
		return {
			...state,
			task: {},
			errors: {},
		};
	case actions.GET_TASK_FAILURE:
		return {
			...state,
			task: {},
			errors: action.errors,
		};
	case actions.CREATE_TASK_SUCCESS:
		return { ...state, task: action.task };

	case actions.CREATE_TASK_REQUEST:
		return { ...state };

	case actions.CREATE_TASK_FAILURE:
		return {
			...state,
			task: {},
			errors: action.errors,
		};
	case actions.UPDATE_TASK_SUCCESS:
		return { ...state, task: action.task };

	case actions.UPDATE_TASK_REQUEST:
		return { ...state };

	case actions.UPDATE_TASK_FAILURE:
		return {
			...state,
			task: {},
			errors: action.errors,
		};

	default:
		return state;
	}
}
